import React, {useState} from 'react';
import './style.scss'
import Carousel from 'react-bootstrap/Carousel';

export const Gallery = (props) => {
    const [index, setIndex] = useState(0);
    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    return (
        <Carousel activeIndex={index} onSelect={handleSelect}>
            {
                props.partners.map((slide, i) => {
                    return (
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={slide}
                                alt="slider image"
                            />
                        </Carousel.Item>
                    )
                })
            }
        </Carousel>
    );
};


export default Gallery;
