import React from 'react'
import {Link, useNavigate} from "react-router-dom";

export const Footer = (props) => {
    const navigate = useNavigate();

    function handleClick() {
        navigate("/");
    }

    return (
        <footer className="header-block footer-block col-12 d-flex justify-content-center">
            <div className="footer-box col-lg-8 col-sm-12 row p-0 d-flex align-items-center justify-content-between">
                <div
                    className="footer-logo col-4 row d-flex align-items-center"
                    onClick={handleClick}
                >
                    <img className="col-2" src="/assets/u482-logo-small.png" alt=""/>
                    <h3 className="col-2 m-0 p-0">U482</h3>
                </div>
                <div className="footer-link-list col-8 row d-flex justify-content-end flex-nowrap">
                    <Link to="/privacy-policy">Privacy policy | </Link>
                    <Link to="/cookie">Умови cookie | </Link>
                    <Link to="mailto:support@u482.net?subject=Зворотній зв'язок&body=Доброго дня">
                        Зв'язатися з нами {props.isMobile && <span> |</span>}
                    </Link>
                </div>
            </div>
        </footer>
    )
}
