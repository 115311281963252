import React from 'react'
import {Header} from "../../header.js";
import {Footer} from "../../components/footer/footer.js";

function Cookie() {
    return (
        <div className="col-12 align-items-center flex-column justify-content-center d-flex pb-5">
            <Header isLanding={false}></Header>
            <div className="col-8 row values-box d-flex flex-wrap justify-content-center">
                {
                    <div>
                        <h2>Умови використання файлів cookie</h2><br/>
                        <h4>Останнє оновлення: 09/07/2023</h4>
                        <br/>
                        <p>Ця сторінка описує, як ми використовуємо файли cookie на нашому веб-сайті. Ви повинні
                            ознайомитися з
                            цими умовами, перш ніж продовжувати використання сайту.
                        </p>

                        <br/>
                        <h2>Що таке файли cookie?</h2><br/>
                        <p> Файли cookie - це невеликі текові файли, які зберігаються на вашому комп'ютері або пристрої
                            при
                            відвідуванні веб-сайту. Вони містять невелику кількість інформації, яка дозволяє
                            ідентифікувати ваш
                            пристрій під час використання сайту.
                        </p>

                        <br/>
                        <h2>Які файли cookie ми використовуємо і для чого?</h2><br/>
                        <p>1. **Аналітичні файли cookie**: Ми використовуємо аналітичні файли cookie для збору
                            інформації про
                            використання нашого веб-сайту. Ці файли cookie дозволяють нам аналізувати трафік, статистику
                            відвідувань і використання сайту для поліпшення його функціональності та надання кращого
                            користувацького досвіду.
                        </p>
                        <p>2. **Файли cookie від сторонніх служб**: Іноді ми можемо використовувати файли cookie від
                            сторонніх
                            служб, таких як рекламні мережі, для відображення відповідних рекламних оголошень під час
                            вашого
                            перебування на нашому сайті. Ці файли cookie можуть зберігати інформацію про ваші
                            відвідування
                            нашого та інших веб-сайтів та використовувати її для персоналізованої реклами.
                        </p>

                        <br/>
                        <h2>Ваша згода</h2><br/>
                        <p>Ви маєте право прийняти або відхилити використання файлів cookie на нашому веб-сайті. Ви
                            можете налаштувати свій
                            веб-браузер
                            таким чином, щоб відхилити всі файли cookie або попереджувати про їх встановлення. Проте,
                            відмова
                            від використання файлів cookie може призвести до обмеження доступу до певних функцій нашого
                            сайту.
                        </p>

                        <br/>
                        <h2>Зміни до умов</h2><br/>
                        <p>Ми можемо час від часу оновлювати ці умови використання файлів cookie, тому рекомендуємо вам
                            регулярно переглядати цю сторінку, щоб бути в курсі змін. Останнє оновлення буде вказано
                            вище.
                        </p>

                        <br/>
                        <h2>Зв'язок</h2><br/>
                        <p> Якщо у вас виникнуть питання або потреба у додатковій інформації щодо використання файлів
                            cookie на
                            нашому сайті, будь ласка, зв'яжіться з нами за допомогою наших контактних реквізитів.
                        </p>
                    </div>
                }
            </div>
            <Footer></Footer>
        </div>
    )
}

export default Cookie;
