import React, {useState} from 'react'

function CardsValues(props) {
    const listOfCards = [
        {
            title: 'Популяризація українських продуктів',
            src: './../../assets/made_in_ukraine.png',
            description: 'Платформа сприятиме швидкому розвитку і поширенню в маси, як новоствореним бізнесам, так і тих, хто уже давно на ринку',
        },
        {
            title: 'Розвиток і підтримка талановитих українців',
            src: './../../assets/couple.png',
            description: 'Саме тут сотні або й тисячі людей зможуть почати свою справу та отримати перший імпульс для швидкого старту',
        },
        {
            title: 'Безпека у свободі слова',
            src: './../../assets/freedom-of-speech.png',
            description: 'Ми хочемо поставити крапку у вічному обмеженню людей у свободі висловлювань та дозволити їм висловлювати власну думку без страху бути не почутим або заблокованим',
        },
        {
            title: 'Захист від шахрайства',
            src: './../../assets/technology.png',
            description: 'Безпека наших користувачів - пріоритет №1 для нас',
        },
        {
            title: 'Забезпечення робочими місцями тисяч українців',
            src: './../../assets/work-from-home.png',
            description: 'Наша команда націлена відкрити десятки офісів по усій Україні, де забезпечити повну зайнятість для тисяч креативних та талановитих співгромадян',
        },
        {
            title: 'Наповнення бюджету та офіційна сплата податків',
            src: './../../assets/payment-method.png',
            description: 'Платформа дасть змогу розпочати сотні бізнес-проєктів, дати новий ковток повітря існуючим бізнесам, що збільшить потік грошових активів до державного бюджету',
        },
        {
            title: 'Підтримка та наближення України до перемоги',
            src: './../../assets/win.jpg',
            description: 'Головне завдання для кожного українця - зробити все для нашої якнайшвидшої перемоги',
        }
    ]

    return (
        <div
            className="values-box d-flex flex-wrap justify-content-center align-items-center">
            {
                listOfCards.map((item) =>
                    <Card data={item} isMobile={props.isMobile}></Card>
                )
            }
        </div>
    )
}

function Card(props) {
    const [isShown, setIsShown] = useState(false);

    function openCloseCard(event, value) {
        if ((event === 'hover' && props.isMobile) || (event === 'click' && !props.isMobile)) return;
        setIsShown(value)
    }

    return (
        <div className={`card ${isShown ? 'card-active' : ''}`}
             onMouseEnter={() => openCloseCard('hover', true)}
             onClick={() => openCloseCard('click', !isShown)}
             onMouseLeave={() => openCloseCard('hover', false)}
        >
            <div className="card-body">
                <h5 className="card-title">{props.data.title}</h5>
                {
                    isShown && (
                        <p className="card-text">{props.data.description}</p>
                    )
                }
                {
                    !isShown && (
                        <img src={props.data.src} className="card-img-top" alt="..."/>
                    )
                }
            </div>
        </div>
    );
}

export default CardsValues;
