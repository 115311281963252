import React, {useEffect, useState} from "react";
import {Header} from "./header";
import './App.scss';
import CountdownTimer from "./components/countdown/countdown.js";
import rocketSvg from './assets/rocket.svg';
import GalleryDesktop from "./components/carousel-desktop/carousel.js";
import CardsValues from "./components/cards-values/cards-values.js";
import CardsInvestment from "./components/cards-investment/cards-investment.js";
import Tokenomic from "./components/tokenomic/tokenomic.js";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHandshake, faHeart, faMap, faPersonCircleQuestion} from "@fortawesome/free-solid-svg-icons";
import {Footer} from "./components/footer/footer.js";
import Gallery from "./components/carousel-mobile/carousel.js";
import RoadMap from "./components/road-map/road-map.js";

function App() {
    document.title = 'U482';
    const [width, setWidth] = useState(window.innerWidth);
    const [isMobile, setIsMobile] = useState(null);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    if (isMobile == null) {
        setIsMobile(width <= 768);
    }
    const list = [
        './../../assets/partners/diya.svg',
        './../../assets/partners/mono.jpeg',
        './../../assets/partners/NP.png',
        './../../assets/partners/qmail.png',
        './../../assets/partners/usfilter.svg',
        './../../assets/partners/whitebit.png',
    ]
    return (
        <div className="App">
            <Header isLanding={true}></Header>

            <div id="timer" className="timer-block col-lg-12 pt-4 pb-4">
                <div className="timer-block-internal">
                    <div className="timer-container">
                        <h1>Готовий до запуску:</h1>

                        <CountdownTimer></CountdownTimer>
                    </div>

                    <img src={rocketSvg} alt="" width={500}/>
                </div>
            </div>
            <div id="about-us" className="about-us-block flex-column d-flex align-items-center col-lg-12">
                <h1 className="icon-header">
                    <FontAwesomeIcon color="white" icon={faPersonCircleQuestion}/>
                    Про нас:
                </h1>
                <h5 className="d-flex col-lg-8">
                    Український стартап, який вирішив голосно заявити про себе та сформувати
                    найбільше ком'юніті
                    українців по цілому світу Головне завдання, якого змінити сприйняття медіа простору та створення
                    платформи, де користувачі не просто витрачатимуть свій час, а зможуть заробляти, розпочинати свій
                    бізнес чи кинути виклик самому собі.
                </h5>
            </div>
            <div id="partners" className="partners-block flex-column d-flex align-items-center col-lg-12">
                <h1 className="icon-header">
                    <FontAwesomeIcon color="white" icon={faHandshake}/>
                    Наші партнери:
                </h1>
                {
                    !isMobile && <GalleryDesktop partners={list}></GalleryDesktop>
                }
                {
                    isMobile && <Gallery partners={list}></Gallery>
                }
            </div>
            <div id="about-project"
                 className="about-project-block flex-column d-flex align-items-center justify-content-center col-lg-12">
                <h1 className="icon-header">Про проект:</h1>
                <h5 className="d-flex col-lg-8 d-flex justify-content-center align-items-center">
                    <div className="ukraine-flag">
                        <div></div>
                        <div></div>
                    </div>
                    "Український патріотичний проєкт, що націлений на популяризацію української мови, культури, музики
                    та
                    відео"
                </h5>
            </div>
            <div id="values"
                 className="values-block d-flex flex-column justify-content-center align-items-center col-lg-12">
                <h1 className="icon-header">
                    <FontAwesomeIcon color="white" icon={faHeart}/>
                    Основні цінності проєкту:
                </h1>

                <CardsValues isMobile={isMobile}></CardsValues>
            </div>
            <div id="investment" className="investment-block flex-column d-flex align-items-center col-lg-12">
                <h1 className="icon-header">
                    <FontAwesomeIcon color="white" icon={faHeart}/>
                    Чому варто інвестувати у наш проєкт?
                </h1>

                <CardsInvestment></CardsInvestment>
            </div>
            <div id="tokenomic" className="tokenomic-block pb-5 flex-column d-flex align-items-center col-lg-12">
                <Tokenomic></Tokenomic>
            </div>
            <div id="road-map" className="pt-5 road-map-block flex-column d-flex align-items-center col-lg-12">
                <h1 className="icon-header">
                    <FontAwesomeIcon color="#534391" icon={faMap}/>
                    Road Map
                </h1>
                <RoadMap></RoadMap>
            </div>
            <Footer isMobile={isMobile}></Footer>
        </div>
    );
}

export default App;
