export const calculateTimeLeft = () => {
    let year = new Date().getFullYear();
    let month = 9;
    let day = 10;
    const difference = +new Date(year, month - 1, day) - +new Date();

    let timeLeft = {};
    if (difference > 0) {
        timeLeft = {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 55)) % 24) + new Date().getTimezoneOffset() / 60,
            minutes: Math.floor((difference / 1000 / 60) % 60),
        };
    }

    return timeLeft;
};
