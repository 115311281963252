import React from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowUpRightDots, faCoins, faGlobe, faPersonRays, faShieldHalved} from "@fortawesome/free-solid-svg-icons";

function CardsInvestment() {
    const listOfCards = [
        {
            title: 'Стрімкий розвиток',
            src: faArrowUpRightDots,
            description: 'Постійне вдосконалення платформи та впровадження нових функцій сприятиме швидкому взлету ціни токена на ринку',
        },
        {
            title: 'Масштабування проекту',
            src: faGlobe,
            description: 'Український ринок - це лише перший етап. У найближчому майбутньому планується вихід як на Європейський, так і міжконтинентальний ринок',
        },
        {
            title: 'Дефляційна структура токену',
            src: faCoins,
            description: 'Проєкт передбачає активне спалення токенів, що сприятиме додатковому росту ціни',
        },
        {
            title: 'Маркетинговий хід',
            src: faPersonRays,
            description: 'На маркетинг виділено 500 тисяч $,а також регулярні колаборації із масштабними брендами та інфлюєнсерами',
        },
        {
            title: 'Безпека та прозорість',
            src: faShieldHalved,
            description: 'Для інвесторів буде створено ком\'юніті із детальним звітом про використання інвестицій та про рух згідно дорожньої карти',
        }
    ]
    return (
        <div className="row values-box d-flex flex-wrap justify-content-center">
            {
                listOfCards.map(item =>
                    <div className="card">
                        {/*<img src={item.src}  alt="..."/>*/}
                        <FontAwesomeIcon className="card-img-top" icon={item.src} />
                        <div className="card-body row">
                            <h5 className="card-title">{item.title}</h5>
                            <p className="card-text">{item.description}</p>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default CardsInvestment;
