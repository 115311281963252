import React from 'react'
import {Bar, Doughnut} from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend, ArcElement,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
);

function Tokenomic() {
    const labels = ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''];
    const data = {
        labels,
        datasets: [
            {
                label: 'Airdrop',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2000000, 3000000, 4000000, 5000000, 6000000, 7000000, 8000000],
                backgroundColor: 'rgba(136,136,136,0.5)',
            },
            {
                label: 'Публічних раунд',
                data: [0, 0, 0, 0, 0, 2800000, 4200000, 5600000, 7000000, 8400000, 9800000, 11200000, 11200000, 11200000, 11200000, 11200000, 11200000, 11200000, 11200000, 11200000, 11200000, 11200000, 11200000, 11200000, 11200000, 11200000, 11200000, 11200000, 11200000, 11200000],
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
            {
                label: 'Приватний раунд',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3500000, 3500000, 5250000, 5250000, 7000000, 7000000, 8750000, 8750000, 10500000, 10500000, 12250000, 12250000, 14000000, 14000000, 14000000, 14000000, 14000000, 14000000],
                backgroundColor: 'rgba(255,168,0,0.5)',
            },
        ],
    };
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
            },
        },
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true
            }
        }
    };
    const optionsDoughnut = {
        responsive: true,
        showDatapoints: true,
    };


    const dataDoughnut = {
        labels: [
            'Айрдроп (2.85%)',
            'Приватний раунд (5%)',
            'Публічний раунд (4%)',
            'IDO/лаунчпад (10%)',
            'Інфлюєнсери (2.85%)',
            'Команда (3.57%)',
            'Винагороди користувачам (25%)',
            'Токени компанії (20%)',
            'Стейкінг (8%)',
            'Insurande fund (11%)',
            'Security fund (7.71%)'
        ],
        datasets: [
            {
                label: '# of Votes',
                data: [
                    8000000,
                    14000000,
                    11200000,
                    28000000,
                    8000000,
                    10000000,
                    70000000,
                    56000000,
                    22400000,
                    30800000,
                    21600000,
                ],
                backgroundColor: [
                    'rgba(255,0,0,0.4)',
                    'rgb(9,44,131,0.4)',
                    'rgb(255,166,0,0.4)',
                    'rgb(69,211,93,0.4)',
                    'rgb(246,246,0,0.4)',
                    'rgb(232,159,248,0.4)',
                    'rgb(219,0,253,0.4)',
                    'rgba(9,104,131,0.4)',
                    'rgb(0,27,75,0.4)',
                    'rgb(246,246,0,0.4)',
                    'rgb(189,196,85,0.4)',
                ],
                borderColor: [
                    'rgba(255,0,0,0.4)',
                    'rgb(9,44,131,0.4)',
                    'rgb(255,166,0,0.4)',
                    'rgb(69,211,93,0.4)',
                    'rgb(246,246,0,0.4)',
                    'rgb(232,159,248,0.4)',
                    'rgb(219,0,253,0.4)',
                    'rgba(9,104,131,0.4)',
                    'rgb(0,27,75,0.4)',
                    'rgb(246,246,0,0.4)',
                    'rgb(189,196,85,0.4)',
                ],
                borderWidth: 1,
            },
        ],
    };

    return (
        <div className="h-auto w-100 d-flex flex-column justify-content-center align-items-center">
            <Bar className="bar-chart" options={options} data={data}/>
            <div className="h-auto w-50">
                <Doughnut width="500" options={optionsDoughnut} className="doughnut-chart" data={dataDoughnut}/>
            </div>
        </div>
    )
}

export default Tokenomic;
