import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './road-map.scss';
import {
    faChartLine,
    faCheck,
    faCopyright,
    faLaptopFile,
    faMobile, faMoneyBillTrendUp,
    faPeopleRoof
} from '@fortawesome/free-solid-svg-icons';


const Roadmap = () => {
    return (
        <div className="roadmap-container mt-5">
            <div className="roadmap-stage left">
                <h3 className="stage-title"><FontAwesomeIcon icon={faCopyright} />Етап 1</h3>
                <div className="stage-details">
                    <h5><b>3</b> квартал 20<b>22</b></h5>
                    <ul>
                        <li>
                            <FontAwesomeIcon icon={faCheck} /> Підтвердження створення проєкту U482
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faCheck} /> Створення бренду
                        </li>
                    </ul>
                </div>
            </div>

            <div className="roadmap-stage right">
                <h3 className="stage-title"><FontAwesomeIcon icon={faMobile} />Етап 2</h3>
                <div className="stage-details">
                    <h5><b>4</b> квартал 20<b>22</b></h5>
                    <ul>
                        <li>
                            <FontAwesomeIcon icon={faCheck} /> Початок розробки додатку
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faCheck} /> Розробка унікальних фішок продукту
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faCheck} /> Створення маркетинг плану проєкту
                        </li>
                    </ul>
                </div>
            </div>

            <div className="roadmap-stage left">
                <h3 className="stage-title"><FontAwesomeIcon icon={faChartLine} />Етап 3</h3>
                <div className="stage-details">
                    <h5><b>1</b> квартал 20<b>23</b></h5>
                    <ul>
                        <li>
                            <FontAwesomeIcon icon={faCheck} /> Приватний раунд для інвесторів
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faCheck} /> Домовленості з криптобіржами про лістинг токену
                        </li>
                    </ul>
                </div>
            </div>

            <div className="roadmap-stage right">
                <h3 className="stage-title"><FontAwesomeIcon icon={faPeopleRoof} />Етап 4</h3>
                <div className="stage-details">
                    <h5><b>3</b> квартал 20<b>23</b></h5>
                    <ul>
                        <li>
                            <FontAwesomeIcon icon={faCheck} /> Public sale
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faCheck} /> Airdrop
                        </li>
                    </ul>
                </div>
            </div>

            <div className="roadmap-stage left">
                <h3 className="stage-title"><FontAwesomeIcon icon={faLaptopFile} />Етап 5</h3>
                <div className="stage-details">
                    <h5><b>4</b> квартал 20<b>23</b></h5>
                    <ul>
                        <li>
                            <FontAwesomeIcon icon={faCheck} /> Закрите тестування проєкту серед кам'юніті
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faCheck} /> Проведення IDO
                        </li>
                    </ul>
                </div>
            </div>

            <div className="roadmap-stage right">
                <h3 className="stage-title"><FontAwesomeIcon icon={faMoneyBillTrendUp} />Етап 6</h3>
                <div className="stage-details">
                    <h5>20<b>24</b></h5>
                    <ul>
                        <li>
                            <FontAwesomeIcon icon={faCheck} /> Вихід проєкту на український та європейський ринок
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faCheck} /> Listing токену на біржах
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};
export default Roadmap;
