import {useEffect, useState} from 'react';
import {calculateTimeLeft} from '../../utils/utils.js';

import './styles.scss';

export const CountdownTimer = () => {
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        setTimeout(() => setTimeLeft(calculateTimeLeft()), 1000);
    }, [timeLeft]);

    return (
        <div className="counter">
            <div className="counter-item">
                <span className="value">{String(timeLeft.days).padStart(2, '0')}</span>
                <span className="label">Дні(в)</span>
            </div>

            <div className="counter-item">
                <span className="value">{String(timeLeft.hours).padStart(2, '0')}</span>
                <span className="label">Годин(и)</span>
            </div>

            <div className="counter-item">
                <span className="value">
                    {String(timeLeft.minutes).padStart(2, '0')}
                </span>
                <span className="label">Хвилин(и)</span>
            </div>
        </div>
    );
};
export default CountdownTimer;
