import React from 'react'
import {Link, useNavigate} from "react-router-dom";

export const Header = (props) => {
    const navigate = useNavigate();

    function handleClick() {
        navigate("/");
    }

    return (
        <header className="header-block col-12 d-flex justify-content-center">
            <div className="col-8 row d-flex align-items-center justify-content-between">
                <div
                    className="header-logo col-4 row d-flex align-items-center"
                    onClick={handleClick}
                >
                    <img className="col-2" src="assets/u482-logo-small.png" alt=""/>
                    <h3 className="col-2 m-0 p-0">U482</h3>
                </div>
                {
                    props.isLanding &&
                    <div className="link-list col-8 row justify-content-end flex-nowrap">
                        <Link to="/#timer">Зворотній відлік</Link>
                        <Link to="/#about-us">Про нас</Link>
                        <Link to="/#partners">Партнери</Link>
                        <Link to="/#values">Цінності</Link>
                        <Link to="/#tokenomic">Токеноміка</Link>
                        <Link to="/#road-map">Road Map</Link>
                    </div>
                }
                {
                    !props.isLanding &&
                    <div className="link-list col-8 row justify-content-end flex-nowrap">
                        <Link to="/">Повернутися на головну</Link>
                    </div>
                }
            </div>
        </header>
    )
}
