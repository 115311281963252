import React from 'react';
import {Carousel} from '@trendyol-js/react-carousel';
import './style.scss'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowCircleLeft, faArrowCircleRight} from '@fortawesome/free-solid-svg-icons'

const arrowRight =
    <div className="arrow-block">
        <FontAwesomeIcon color="white" icon={faArrowCircleRight}/>
    </div>
const arrowLeft =
    <div className="arrow-block">
        <FontAwesomeIcon color="white" icon={faArrowCircleLeft}/>
    </div>

export const GalleryDesktop = (props) => {
    return (
        <Carousel
            show={4}
            slide={4}
            swiping={true}
            infinite={true}
            transition={0.5}
            leftArrow={arrowLeft}
            rightArrow={arrowRight}
            responsive={true}
        >
            {
                props.partners.map((item) =>
                    <img src={item} alt=""/>
                )
            }
        </Carousel>
    );
};


export default GalleryDesktop;
